import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./further-information.module.scss";
import { Accordion } from "react-bootstrap";
import TGSelection from "../../../../../shared/tg-selection";
import TGInput from "../../../../../shared/tg-input";
import { TGRadio } from "../../../../../shared/tg-radio";
import TGButton from "../../../../../shared/tg-button";
interface IProps {
  children: ReactNode,
  header: string
}

interface Props {
  setIndex : Function;
}

const AccordianComponent = (props: IProps) => {
  return  <Accordion
  data-testid="accordion"
  className={styles["further-information-container"]}
>
    <Accordion.Item
      eventKey={"0"}
      className={styles["further-information-head-item"]}
    >
      <Accordion.Header className={`${styles["further-information-header"]} p-4`}>
        {props.header}
      </Accordion.Header>
      <Accordion.Body className={styles["further-information-body"]}>
        {props?.children}
      </Accordion.Body>
    </Accordion.Item>
</Accordion>
}

const FurtherInformation = (props: Props) => {
  
  return (
    <div className="d-flex flex-column gap-3">
     <div className={styles["further-information-header"]}>{`Further Information (Optional)`}</div>
     <div className={styles["further-information-desc"]}>If you have more information that could help us understand your situation better, please share it here. While optional, these details can be crucial for us to fully understand your needs.</div>
    <AccordianComponent header="Contact Details">
    <div className={styles["further-information-main-wrapper"]}>
      <div className={styles["further-information-wrapper"]}>
        <TGSelection label="Country" listData={[]} onChange={()=>{}} isLoading={false} disableSearch={true}/>
      </div>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Address Line (Optional)" onChange={()=>{}} validation="address" placeholder="Example: House No., Alley, Road"/>
      </div>
    </div>
    <div className={styles["further-information-main-wrapper"]}>
      <div className={`${styles["further-information-wrapper"]} ${styles["further-information-province-wrapper"]}`}>
        <TGSelection label="Province, Country, Postal Code" listData={[]} onChange={()=>{}} isLoading={false} disableSearch={true}/>
      </div>
    </div>
    <div className={styles["further-information-main-wrapper"]}>
      <div className={styles["further-information-phone-number-wrapper"]}>
        <TGSelection label="Phone Number" listData={[]} onChange={()=>{}} isLoading={false} disableSearch={true} placeholder="Country"/>
        <TGInput label="" placeholder="00-000-0000" onChange={()=>{}} />
      </div>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Fax (Optional)" onChange={()=>{}} validation="language"/>
      </div>
    </div>
    </AccordianComponent>
    <AccordianComponent header="Flight Details">
    <div className={styles["further-information-main-wrapper"]}>
      <div className={`${styles["further-information-wrapper"]} ${styles["further-information-province-wrapper"]}`}>
        <TGSelection label="Flight From" listData={[]} onChange={()=>{}} isLoading={false} disableSearch={true}/>
      </div>
    </div>
    <div className={styles["further-information-main-wrapper"]}>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Royal Orchid Plus Number" onChange={()=>{}} placeholder="Example: ZZ9999" validation="rop"/>
      </div>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Flight Number" onChange={()=>{}} placeholder="Example: TG316"/>
      </div>
    </div>
    <div className={styles["further-information-main-wrapper"]}>
      <div className={styles["further-information-wrapper"]}>
        <TGSelection label="Flight Class" listData={[{id:1, listName:"First"}, {id:2, listName:"Business"}, {id:3, listName:"Economy"}]} onChange={()=>{}} isLoading={false} disableSearch={true}/>
      </div>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Flight Date" onChange={()=>{}} />
      </div>
    </div>
    <div className={styles["further-information-main-wrapper"]}>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Booking Reference No." onChange={()=>{}} placeholder="Example: 6XWCN6" validation="language" />
      </div>
      <div className={styles["further-information-wrapper"]}>
        <TGInput label="Seat No." onChange={()=>{}} placeholder="Example: 0217101181" validation="language"/>
      </div>
    </div>
    <div className={`${styles["further-information-main-wrapper"]} ${styles["further-information-province-wrapper"]}`}>
     <div className={styles["further-information-wrapper"]}>
        <TGInput label="Baggage Reference No." onChange={()=>{}} validation="language" />
      </div>
    </div>
    </AccordianComponent>
    <AccordianComponent header="Additional Info">
    <div className={styles["further-information-main-wrapper"]}>
      <div className={styles["further-information-wrapper"]}>
        <TGSelection label="Channel" listData={[{id:1, listName:"Search Engine"}, {id:2, listName:"Magazine"}, {id:3, listName:"Another Site"}, {id:4, listName:"A Business"}]} onChange={()=>{}} isLoading={false} disableSearch={true}/>
      </div>
    </div>
    <div>
    <div className={styles["further-information-description"]}>
      Would you like to receive our future product specials, news & information via E-mail?
    </div>
    <div className={styles["further-information-radio-wrapper"]}>
    <TGRadio
      options={[{id: 1, label: "Yes"}, {id: 2, label: "No"}]}
      selected={-1}
      onChange={(opt: any) => {}}
      name="future-product"
      isBorder={true}
      align="column"
          />
    </div>
    </div>
    <div>
    <div className={styles["further-information-description"]}>
    May we post your Feedback on our site?
    </div>
    <div className={styles["further-information-radio-wrapper"]}>
    <TGRadio
      options={[{id: 1, label: "Yes"}, {id: 2, label: "No"}]}
      selected={-1}
      onChange={(opt: any) => {}}
      name="feedback"
      isBorder={true}
      align="column"
          />
    </div>
    </div>
    </AccordianComponent>
    <div className={`${styles["further-information-button-wrapper"]}`}>
    <TGButton label="Back" onClick={()=> {props.setIndex(0)}} variant="secondary"/>
    <TGButton label="Continue" onClick={()=> {props.setIndex(2)}} variant="primary"/>
    </div>
    </div>
  );
};

export default FurtherInformation;
