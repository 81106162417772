import React, { useEffect, useRef, useState } from "react";
import styles from "./feedback-content.module.scss";
import TGIcon from "../../../../shared/tg-icon";
import TGSelection from "../../../../shared/tg-selection";
import TGInput from "../../../../shared/tg-input";
import FileAttachment from "../file-attachment";
import FurtherInformation from "./further-information";
import { ResetUnlockBanner as CommonWrapper } from "../../pages/reset-unlock/banner";
import TGButton from "../../../../shared/tg-button";
import { useNavigate } from "react-router-dom";
import TGProgressBar from "../../../../shared/tg-progressbar";

const feedbackSubject = [
    {
      id: 1,
      listName: "Baggage Claims"  
    },
    {
        id: 2,
        listName: "Fares & Ticketing"  
    },
    {
        id: 3,
        listName: "Internet Fares"  
    },
    {
        id: 4,
        listName: "Internet Reservation"  
    },
    {
        id: 5,
        listName: "Modify Booking"  
    },
    {
        id: 6,
        listName: "Royal Orchid Holidays"  
    },
    {
        id: 7,
        listName: "Schedule & Availability"  
    }
]

const FeedbackContent: React.FC = () => {
  const [index, setIndex] = useState(0)
  const navigate = useNavigate();
  return (
    <div className={styles["feedback-outer-wrapper"]}>
      <CommonWrapper imageText="ENHANCE YOUR EXPERIENCE" descriptionText="Your feedback drives our progress. Help us improve by sharing your thoughts">
      <div className="d-flex gap-1 w-100">
        <TGProgressBar currentValue={50} customProgressBarClass=""/>
        <TGProgressBar currentValue={0} customProgressBarClass=""/>
      </div>
      {!index ? <>
      <div className={styles["feedback-content-header"]}>Your Feedback Matters</div>
      <div className={`${styles["feedback-container"]} d-flex flex-column gap-3 w-100`}>
      <div className={styles["feedback-content-main-wrapper"]}>
      <div className={styles["feedback-content-wrapper"]}>
        <TGSelection label="Subject" listData={feedbackSubject} onChange={()=>{}} isLoading={false} disableSearch={true}/>
      </div>
      <div className={styles["feedback-content-wrapper"]}>
        <TGInput label="Email" validation="language" onChange={()=>{}} placeholder="Example: THAI@gmail.com" />
      </div>
      </div>
      <div className={styles["feedback-content-main-wrapper"]}>
      <div className={styles["feedback-content-wrapper"]}>
        <TGInput label="First Name and Middle Name" validation="name" onChange={()=>{}} />
      </div>
      <div className={styles["feedback-content-wrapper"]}>
        <TGInput label="Last Name" validation="name" onChange={()=>{}} />
      </div>
      </div>
      <div className={styles["feedback-content-textarea"]} >
        <TGInput label="Feedback" onChange={()=>{}} validation="language"/>
      </div>
      <FileAttachment />
      <TGButton label="Continue" onClick={()=> setIndex(1)} variant="primary"/>
      </div>
      </> : index ===1 && <FurtherInformation setIndex={setIndex}/>}
      {index === 2 && <div>
        <div className={styles["feedback-final-message-header"]}>We have received your feedback</div>
        <div className={styles["feedback-final-message-desc"]}>Our team will response to you shortly. Please contact call center +66 2 356 1111 if you have further questions.</div>
        <TGButton label="Back to Home" onClick={()=> navigate("/")} variant="primary"/>
        </div>}
    </CommonWrapper>
    </div>
  );
};

export default FeedbackContent;
