import React, { useEffect } from "react";
import styles from "./footers.module.scss";
import SubscribeNewsLetter from "../../../../shared/subscribe-news-letter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slice/RootReducer";
import { config } from "../../../../config/global";
import {
  getFooterDataError,
  getFooterDataSuccess,
} from "../../slice/footerSlice";
import TGPlaceholder from "../../../../shared/tg-placeholder";
import {
  getAppStoreDataFailure,
  getAppStoreDataSuccess,
} from "../../slice/appStoreSlice";
import { AppStoreData } from "../../models/models";
import Star from "../../../../../public/assets/star-footer.png";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { Accordion, ListGroup } from "react-bootstrap";
import CookieOneTrustComponent from "../../component/cookie-onetrust";
import {
  getSocialMediaDataFailure,
  getSocialMediaDataSucess,
} from "../../slice/socialMediaSlice";

export const Footers = () => {
  const dispatch = useDispatch();
  const footerSelector = useSelector(
    (state: RootState) => state?.osciReducer?.footer?.footerData
  );
  const isLoadingFooterData = useSelector(
    (state: RootState) => state?.osciReducer?.footer?.isLoading
  );
  const appStore = useSelector(
    (state: RootState) => state?.osciReducer?.app.appStoreDetails
  );
  const socialMedia = useSelector(
    (state: RootState) => state?.osciReducer?.socialMedia.social
  );
  let isDesktopView = useScreenSize().deviceSize.width > 767;
  let API_URL = config.TEAMSITE_FOOTER_ENDPOINT;
  let APPSTORE_URL = config.TEAMSITE_APPSTORE_API;
  const SOCIAL_URL = config.TEAMSITE_SOCIAL_PLATFORMS;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: API_URL,
      isTeamsite: true,
      successAction: getFooterDataSuccess,
      errorAction: getFooterDataError,
    });
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: APPSTORE_URL,
      isTeamsite: true,
      successAction: getAppStoreDataSuccess,
      errorAction: getAppStoreDataFailure,
    });
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: SOCIAL_URL,
      isTeamsite: true,
      successAction: getSocialMediaDataSucess,
      errorAction: getSocialMediaDataFailure,
    });
    console.log("Socaial Platform:", socialMedia.footerSectionName);
  }, [dispatch]);

  // about Thai section - desktop view
  function TopContainer() {
    return footerSelector.footerDetails?.map((x, i) =>
      x.footerGroup?.map((y, ind) => (
        <div className={styles.topContainer} key={y.footerGroupID}>
          <div className={styles.footerMenu}>
            {isLoadingFooterData ? (
              <TGPlaceholder
                typeClass="p"
                animationVariant="wave"
                variant="text"
                sizeClass={12}
                numberOfLines={4}
                loaderStyleClass="textLoader"
              />
            ) : (
              y.footerItems?.map((z) => (
                <div className={styles.subTabs} key={z.footerSectionId}>
                  <span className={styles.sectionText}>
                    {z.footerSectionName}
                  </span>
                  <ul className={`nav flex-column ${styles.footerSub}`}>
                    {z.footerSectionNavigation?.map((a, inde) => (
                      <li key={inde}>
                        <a
                          href={a.footerSubItemNavigation}
                          className={`nav-link p-0 ${styles.subOpt}`}
                          role="button"
                        >
                          <span className={styles.subItemText}>
                            {a.footerSubItemName}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            )}
          </div>
        </div>
      ))
    );
  }

  //  about Thai Section - Mobile View
  function MobileViewTopConatiner() {
    return footerSelector.footerDetails?.map((x, index: number) =>
      x.footerGroup?.map((y, ind) =>
        y.footerItems?.map(
          (z, i) =>
            i < z.footerSectionNavigation?.length && (
              <Accordion className={styles["list-container"]} key={i}>
                <Accordion.Item
                  eventKey={index.toString()}
                  className={styles["list-head-item"]}
                >
                  <Accordion.Header className={styles["list-header"]}>
                    {z.footerSectionName}
                  </Accordion.Header>
                  <Accordion.Body className={styles["list-body"]}>
                    <ListGroup className={styles["list-wrapper"]}>
                      {z.footerSectionNavigation?.map((a, indexN) => (
                        <ListGroup.Item
                          className={styles["list-item"]}
                          onClick={() => window.open(a.footerSubItemNavigation)}
                          key={indexN}
                        >
                          {a.footerSubItemName}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                  <div className={styles.bottomLn}></div>
                </Accordion.Item>
              </Accordion>
            )
        )
      )
    );
  }

  //  social Application Section
  function SocialPlatforms() {
    return (
      <div className={styles.social} key={socialMedia.footerSectionId}>
        <span className={styles.platformText}>
          {socialMedia.footerSectionName}
        </span>
        <div className={styles.platform}>
          {socialMedia.platforms?.map((z, id) => (
            <a href={z.iconLink} key={id}>
              <img src={`/assets/${z.iconId}.svg`} alt={z.iconName} />
            </a>
          ))}
        </div>
      </div>
    );
  }

  // application Store Section
  function AppStore() {
    return appStore?.map((store: AppStoreData, idx: number) => (
      <div className={styles.appStoreContainer} key={idx}>
        <span className={styles.appText} role="heading">
          {store.sectionHeader}{" "}
        </span>
        <span className={styles.appDesc} role="heading">
          {store.sectionDescription}
        </span>
        <span className={styles.download} role="heading">
          {store.storeHeader}
        </span>
        <span className={styles.appStore}>
          <a href={store.appStoreLink}>
            <img src={store.appStore} alt="App Store" />
          </a>
          <a href={store.playStoreLink}>
            <img src={store.playStore} alt="Play Store" />
          </a>
        </span>
      </div>
    ));
  }

  // FooterSection
  return (
    <div className={styles.FooterContainer}>
      <footer className={`py-5 column ${styles.padding}`}>
        {isDesktopView ? (
          <div className={`global-content-padding ${styles.subTask}`}>
            <TopContainer />
          </div>
        ) : (
          <MobileViewTopConatiner />
        )}
        <div className="global-content-padding">
          <SocialPlatforms />
        </div>

        <div className={`global-content-padding ${styles.bottomContainer}`}>
          <SubscribeNewsLetter />
          <AppStore />
        </div>
      </footer>
      <div className={styles.copyrightDiv}>
        <span className={styles.copyrightText}>
          {footerSelector.copyrights}
        </span>
        <img
          src={Star}
          alt="star_alliance"
          className={styles.copyrightDivImg}
        />
      </div>

      <CookieOneTrustComponent />
    </div>
  );
};
