/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styles from "./country-language.module.scss";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Tab,
  Tabs,
} from "react-bootstrap";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { useDispatch, useSelector } from "react-redux";
import { countryDataRequest } from "../../slice/header/countrySlice";
import { fetchOrSaveLocalizeValue } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import TGIcon from "../../../../shared/tg-icon";
import TGIconLabel from "../../../../shared/tg-icon-label";
import { useOutsideClick } from "../../../../utils/hook/useClickOutside";
import TGNavbar from "../../../../shared/tg-navbar";
import TGSelection from "../../../../shared/tg-selection";
import { TGRadio } from "../../../../shared/tg-radio";
const ContryLanguageWidget = React.forwardRef(({}, parentRef) => {
  const [show, setShow] = useState(false);
  const countryData = useSelector((state: any) => state?.osciReducer?.country.CountryDetails);
  const [activeElement, setActiveElement] = useState(
    countryData?.[0]?.regionName.toLowerCase()
  );
  const resolution = useScreenSize()?.deviceSize?.width;
  const isDesktopView = resolution > 768;
  const [isTabClicked, setIsTabClicked] = useState(false);
  const { t, i18n } = useTranslation();
  const locale: any = fetchOrSaveLocalizeValue(
    countryData,
    i18n?.language?.toLowerCase().split("-"),
    true
  );
  const currentConfig = i18n?.language?.split("-");
  const isWiderView = resolution > 1080;
  const dispatch = useDispatch();
  const ref: any = useRef();
  const handleClickOutside = () => {
    isWiderView && setShow(false);
  };
  const containerRef = useOutsideClick(handleClickOutside);

  useEffect(() => {
    if (countryData.length === 0) {
      dispatch(countryDataRequest());
    }
  }, []);

  useEffect(() => {
    if (resolution > 1080) {
      document.documentElement.style.setProperty(
        "--body-overflow",
        show ? "hidden" : "unset"
      );
    }

    if (show && resolution > 1080) {
      const marginFromTop = parentRef?.current?.getBoundingClientRect()?.top;
      console.log("testmargin", marginFromTop);
      document.documentElement.style.setProperty(
        "--location-space",
        `${84 + marginFromTop}px`
      );
    }
  }, [show]);

  useEffect(() => {
    setTimeout(() => {
      if (show) {
        const selectedEle = document.getElementById(locale?.regionCode);
        selectedEle?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [show]);

  const handleScroll = (e: any) => {
    const id = e.toLowerCase();
    setActiveElement(id);
    const ele = document.getElementById(id);
    setIsTabClicked(true);
    ele?.scrollIntoView();
  };

  const isInViewport = (element: any) => {
    const rect = element.getBoundingClientRect();
    const ele = document
      .getElementById("container-dom")
      ?.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= ele?.bottom &&
      rect.right <= ele?.right
    );
  };

  const isInMobileViewPort = (element: any) => {
    const ele = document.getElementById("container-dom");
    const rect1: any = ele?.getBoundingClientRect();
    const rect2 = element.getBoundingClientRect();
    if (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleContentScroll = () => {
    document.documentElement.style.setProperty(
      "--country-language-container-scroll-behaviour",
      "unset"
    );
    if (!isTabClicked) {
      if (ref.current.scrollTop < 300) {
        setActiveElement(countryData?.[0]?.regionName?.toLowerCase());
      } else {
        countryData.forEach((x: any) => {
          const ele = document.getElementById(x?.regionName?.toLowerCase());
          if (isDesktopView && isInViewport(ele)) {
            console.log("viewport", isInViewport(ele), ele);
            setActiveElement(x?.regionName?.toLowerCase());
          } else if (!isDesktopView && isInMobileViewPort(ele)) {
            console.log("viewport", isInViewport(ele), ele);
            setActiveElement(x?.regionName?.toLowerCase());
          }
        });
      }
    } else setIsTabClicked(false);
  };

  const handleSelection = (
    selectedCountryData: any,
    selectedLanguageData: any
  ) => {
    const currentLanguage: any = i18n.language?.split("-");
    setShow(false);
    let str = window.location
      .toString()
      .replace(
        `${currentLanguage?.[0]?.toLowerCase()}-${currentLanguage?.[1]}`,
        `${removeSpace(
          selectedLanguageData?.languageCode
        )?.toLowerCase()}-${removeSpace(
          selectedCountryData?.countryCode
        )?.toLowerCase()}`
      );
    window.location.assign(str);
  };

  const removeSpace = (str: string) => {
    return str.replace(/\s/g, "");
  };

  const getRegionData = () => {
    const resArr = countryData.map((item: any, index: number) => {
      return {
        ...item,
        label: item.regionName,
        id: index,
      };
    });
    return resArr;
  };

  const getSelectedValue = (val: string) => {
    const res = countryData?.filter(
      (x: any) => x?.regionName?.toLowerCase() === val
    );
    return res?.[0]?.regionName;
  };

  return (
    <>
      {typeof locale?.countryCode !== "undefined" && (
        <div
          className={styles["location-text"]}
          onClick={() => setShow(true)}
          role="button"
          tabIndex={0}
        >
          <TGIconLabel
            label={`${locale?.countryNameEN}-${locale?.languageName}`}
            icon="location-icon"
            position="left"
            size="16px"
            fillColor={isWiderView ? "#6A6D87" : "#9C7DD4"}
          />
        </div>
      )}
      <Offcanvas
        show={show}
        placement={useScreenSize()?.deviceSize?.width < 1080 ? "start" : "top"}
        className={styles["location-wrapper"]}
        backdropClassName={styles["location-backdrop"]}
      >
        <div></div>
        {!isDesktopView && (
          <div className={styles["location-navigation-back"]}>
            <TGNavbar label="Back" handleClick={() => setShow(false)} />
          </div>
        )}
        <div ref={containerRef}>
          <div
            className={`${styles["location-inner-wrapper"]} global-content-padding`}
          >
            {isDesktopView && (
              <OffcanvasHeader className={styles["location-header"]}>
                <div>{t("label_country_header")}</div>
                <div
                  onClick={() => setShow(false)}
                  className={styles["close-icon"]}
                >
                  <TGIcon icon="close-icon" fillColor="" size="28px" />
                </div>
              </OffcanvasHeader>
            )}
            <OffcanvasBody className={styles["location-body"]}>
              {isDesktopView ? (
                <div className="d-flex flex-column">
                  <Tabs
                    className={styles["location-region-sidebar"]}
                    onSelect={(e) => handleScroll(e)}
                    activeKey={activeElement}
                    data-testid="region-sidebar"
                  >
                    {countryData.map((z: any, index: number) => (
                      <Tab
                        tabClassName={`${
                          styles["location-region"]
                        } ${z?.regionName?.toLowerCase()} tab-sidebar-${z?.regionName?.toLowerCase()}`}
                        key={index + "region"}
                        eventKey={z?.regionName?.toLowerCase()}
                        title={z.regionName}
                      />
                    ))}
                  </Tabs>
                </div>
              ) : (
                <div className={styles["location-region-selection-container"]}>
                  <TGSelection
                    label=""
                    isLoading={false}
                    isCustomChild={true}
                    headerLabel="Region"
                    value={getSelectedValue(activeElement)}
                    backdropClass={styles["location-region-dropdown-backdrop"]}
                    offcanvasClass={styles["location-region-dropdown"]}
                    disableSearch={true}
                  >
                    <TGRadio
                      name="region-name"
                      options={getRegionData()}
                      onChange={(e) => {
                        handleScroll(e.regionName);
                      }}
                      isBorder={true}
                      align="column"
                      selected={
                        getRegionData()?.filter(
                          (x: any) =>
                            activeElement?.toLowerCase() ===
                            x?.label?.toLowerCase()
                        )[0]?.id
                      }
                    />
                  </TGSelection>
                </div>
              )}
              <div
                className={styles["location-country-sidebar"]}
                id="container-dom"
                ref={ref}
                onScroll={handleContentScroll}
              >
                {countryData.map((x: any, index: number) => (
                  <div
                    className={styles["location-main-wrapper"]}
                    id={x?.regionName?.toLowerCase()}
                    key={index + "country_name"}
                  >
                    <div
                      className={styles["location-country-text"]}
                      id={x?.regionCode}
                    >
                      {x?.regionName}
                    </div>
                    <div className={styles["location-sidebar-content"]}>
                      {x.countryDetails.map((y: any, index: number) => (
                        <div
                          className={`${
                            styles["location-country-language-wrapper"]
                          } ${
                            removeSpace(y?.countryCode)?.toLowerCase() ===
                              currentConfig?.[1]?.toLowerCase() &&
                            styles["location-country-language-wrapper-selected"]
                          }`}
                          key={index + "countryWrapper"}
                        >
                          <div className="d-flex">
                            {[y.languageDetails[0]].map(
                              (z: any, cIndex: number) => (
                                <div key={cIndex + "language"}>
                                  <div
                                    className={
                                      styles["location-country-name-text"]
                                    }
                                    id={removeSpace(y.countryCode)}
                                  >
                                    {z.countryName}&nbsp;
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <div className="d-flex">
                            {y.languageDetails.map((z: any, cIndex: number) => (
                              <div key={cIndex + "lng"}>
                                <div
                                  onClick={() => handleSelection(y, z)}
                                  className={styles["location-language-text"]}
                                >
                                  <span className={styles["location-lng"]}>
                                    {z.languageName}&nbsp;
                                  </span>
                                  {removeSpace(
                                    z?.languageCode
                                  )?.toLowerCase() === currentConfig?.[0] &&
                                    removeSpace(
                                      y?.countryCode
                                    )?.toLowerCase() ===
                                      currentConfig?.[1]?.toLowerCase() && (
                                      <TGIcon
                                        icon="check-circled"
                                        fillColor=""
                                        size="16px"
                                      />
                                    )}
                                  &nbsp;
                                  {cIndex < y.languageDetails.length - 1 && "|"}
                                  &nbsp;
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </OffcanvasBody>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default ContryLanguageWidget;
